<template>
    <b-row
      
    >
      <b-col cols="12">
        <b-card>
      <b-card-header>
        <h5>Filtrages</h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="10"
            lg="10"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="">Type</label>
                <v-select
                v-model="payloadFilter.type"
                  :options="typeOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>

              <b-col
              v-if="payloadFilter.type == 1"
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="">Trimestre</label>
                <v-select
                  v-model="payloadFilter.quarter"
                  :options="quarterOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
              
              <b-col
              v-if="payloadFilter.type == 2"
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="">Mois</label>
                <v-select
                  
                  v-model="payloadFilter.month"
                  :options="monthOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>

              <b-col
              
                cols="12"
                sm="6"
                md="4"
                lg="4"
              >
                <label for="">Année</label>
                <v-select
                  v-model="payloadFilter.year"
                  :options="yearOptions"
                  class="invoice-filter-select"
                  :reduce="(item) => item.value"
                >
                  <template #selected-option="{ label }">
                    <span class="text-truncate overflow-hidden">
                      {{ label }}
                    </span>
                  </template>
                </v-select>
              </b-col>
            
            </b-row>
          </b-col>
          <b-col
                cols="12"
                sm="6"
                md="2"
                lg="2"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="applyFilterTauxStatisticsAction()"
                >
                  <div v-if="isReLoadFilterData">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Filtrer</span>
                </b-button>
          </b-col>
          <b-col
                cols="12"
                sm="6"
                md="2"
                lg="2"
                class="mt-2"
              >
                <b-button
                  variant="primary"
                  @click="eraseTauxStatistics()"
                >
                  <div v-if="isEraseFilterData">
                    <span> Chargement ... </span>
                    <b-spinner small />
                  </div>

                  <span
                    v-else
                    class="text-nowrap font-medium-1"
                  >Effacer</span>
                </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
        <b-card>
          <b-card-header>
            <b-card-title>TAUX</b-card-title>
          </b-card-header>
          <b-row>
            <b-col
           
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-2"
            >
              <b-button
                ref="reset"
                variant="primary"
                @click="resetTauxStatistics()"
               
              >
                <div v-if="isReLoadDataTaux"  >
                  <span> Chargement ... </span>
                  <b-spinner small />
                </div>
                <span v-else class="text-nowrap font-medium-1">Actualiser</span>
              </b-button>
            </b-col>
            
          </b-row>
          <div
            v-if="isTauxStatisticsLoading"
            class="d-flex justify-content-center"
          >
            <b-spinner
              variant="info"
              style="width: 3rem; height: 3rem;"
            />
          </div>
          <b-row
            v-else
            class="mb-2"
          >
          
            <b-col
              v-for="(item , index) in Object.entries(getTauxStatistics.rate)"
              :key="index"
              lg="4"
              sm="6"
              style="height:max-content !important;"
            >
  
              <statistic-card-horizontal
                isTaux
                :icon="item[1].variation < 0 ? 'TrendingDownIcon': item[1].variation == 0 ? 'ArrowRightIcon' : 'TrendingUpIcon'"
                :color="item[1].variation < 0 ? 'danger': item[1].variation == 0 ? 'info':'success'"
                :statistic="item[0].includes('Taux de croissance') ? item[1].variation :item[1].current.toLocaleString()"
                :statistic-title="
                  item[0]"
                :statistic-description="
                  (item[1].variation  < 0 &&  item[1].previous_month !== undefined) ? `En diminution par rapport au mois de ${item[1].previous_month}` : (item[1].variation  > 0 &&  item[1].previous_month !== undefined ) ? `En croissance par rapport au mois de ${item[1].previous_month}` : (item[1].variation  == 0 &&  item[1].previous_month !== undefined ) ? `Constant par rapport au mois de ${item[1].previous_month}` : (item[1].variation  < 0 &&  item[1].previous_year !== undefined) ? `En diminution par rapport à l\'année  ${item[1].previous_year}` : (item[1].variation > 0 &&  item[1].previous_year !== undefined) ? `En croissance par rapport à l\'année ${item[1].previous_year}` : (item[1].variation == 0 &&  item[1].previous_year !== undefined) ? `Constant par rapport à l\'année ${item[1].previous_year}` : (item[1].variation < 0 &&  item[1].previous_quarter !== undefined) ? `En diminution par rapport au trimestre ${item[1].previous_quarter} de l'année ${item[1].previous_quarter_year}`  : (item[1].variation == 0 &&  item[1].previous_quarter !== undefined) ? `Constant par rapport au trimestre ${item[1].previous_quarter} de l'année ${item[1].previous_quarter_year}`  : `En croissance par rapport au trimestre  ${item[1].previous_quarter} de l'année ${item[1].previous_quarter_year}`
                 "
              />            

            </b-col>
  
          </b-row>
  
        </b-card>
      </b-col>
  
    </b-row>
  
  </template>
  
  <script>
  import {
    BCard,
    BCardHeader,
    // BCardText,
    BCardTitle,
    // BCardSubTitle,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    // BAlert,
    BTable,
    BMedia,
    BAvatar,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BSpinner,
  } from 'bootstrap-vue'
  
  // import VueApexCharts from 'vue-apexcharts'
  import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  import { avatarText } from '@core/utils/filter'
  import vSelect from 'vue-select'
  import flatPickr from 'vue-flatpickr-component'
  import { mapActions, mapGetters } from 'vuex'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import apexChatData from './apexChartData'
  import StatisticsCard from './StatisticsCard.vue'
  
  export default {
    components: {
      BCard,
      BButton,
      BRow,
      BCol,
      // VueApexCharts,
      // BAlert,
      BCardHeader,
      // BCardText,
      BCardTitle,
      // BCardSubTitle,
      BFormInput,
      BFormGroup,
      BTable,
      BMedia,
      BAvatar,
      // BDropdown,
      // BDropdownItem,
      BPagination,
      BSpinner,
      flatPickr,
      StatisticCardHorizontal,
      vSelect,
      // ToastificationContent,
      StatisticsCard,
    },
    data() {
      return {
        apexChatData,
        invoices: [],
        isDateRangeStatisticsLoading: false,
        isMonthRangeStatisticsLoading: false,
        rangeDate: null,
        isLoading: true,
        commissioonsPerPeriod: null,
        proAmountPerPeriod: null,
        turnoverPerPeriod: null,
        totalAmountOfWagesPaid: null,
        totalAmountOfUnpaidWages: null,
        totalAmountOfWagesPaidByCustomers: null,
        turnoverGrowthRate: null,
        oneTimeOrderRevenueGrowthRate: null,
        growthRateOfNumberOfRegisteredProfessionals: null,
        growthRateOfNumberOfValidatedProfessionals: null,
        growthRateOfNumberOfRegisteredEmployees: null,
        growthRateOfNumberOfValidatedEmployees: null,
        employeesDropoutRate: null,
        customerGrowthRate: null,
        growthRateOfNumberOfCustomersInvitedByCC: null,
        placementFees: null,
        monthSelected: '',
        yearSelected: '',
        config: { mode: 'range' },
        avatarText,
        isEraseFilterData: false,
        isReLoadFilterData:false,
        isReLoadDataTaux: false,
        payloadFilter:{
          quarter: "",
          month: "",
          year: "",
         
        },
        typeOptions: [
        {
          label: 'Trimestre',
          value: 1,
        },
        {
          label: 'Mois',

          value: 2,
        },
        {
          label: 'Année',

          value: 3,
        },
      ],
      monthOptions: [
        { value: 1, label: "Janvier" },
        { value: 2, label: "Février" },
        { value: 3, label: "Mars" },
        { value: 4, label: "Avril" },
        { value: 5, label: "Mai" },
        { value: 6, label: "Juin" },
        { value: 7, label: "Juillet" },
        { value: 8, label: "Août" },
        { value: 9, label: "Septembre" },
        { value: 10, label: "Octobre" },
        { value: 11, label: "Novembre" },
        { value: 12, label: "Décembre" },
      ],
      type: "",
      quarterOptions: [
        {
          label: 'Premier trimestre',
          value: 1,
        },
        {
          label: 'Deuxième trimestre',

          value: 2,
        },
        {
          label: 'Troisième trimestre',

          value: 3,
        },
        {
          label: 'Quatrième trimestre',

          value: 4,
        },
      ],
      }
    },
    computed: {
      ...mapGetters('services', [
        'getServices',
        'getMostRequestedServices',
        'getRecurringServices',
      ]),
      ...mapGetters('auth', ['getUser']),
      ...mapGetters('customers', ['getCustomers']),
      ...mapGetters('orders', ['getOrders', 'getDirectOrders']),
  
      ...mapGetters('professionals', [
        'getProfessionals',
        'getBestProfessionals',
        'getProfessionalsPerServicesAndAddress',
        'getEmployees',
      ]),
      ...mapGetters('statistics', [
        'getProAmount',
        'getProfessionalsAmountPerServices',
        'getCommissins',
        'getCustomerConversion',
        'getSatisfiedOrder',
        'getUnsatisfiedOrder',
        'getStatistics',
        'getTauxStatistics',
      ]),
      ...mapGetters([
        'isCommissionLoading',
        'isProAmountLoading',
        'isServicesLoading',
        'isRecurringServicesLoading',
        'isCustomerCommisionLoading',
        'isProAmountPerServiceLoading',
  
        'isCustomersLoading',
        'isReferredCustomersLoading',
        'isOrdersLoading',
        'isDirectOrdersLoading',
        'isProfessionalsLoading',
        'isSatisfiedOrderLoading',
        'isUnsatisfiedOrderLoading',
        'isEmployeesLoading',
        'isStatisticsLoading',
        'isNumbersStatisticsLoading',
        'isTauxStatisticsLoading'
      ]),
  
      typeSelect(){

      },
  
    
      yearOptions() {
        const data = []
        for (let i = 2021; i <= Number((new Date()).getFullYear().toString()); i++) {
          data.push({
            label: i.toString(),
            value: i.toString(),
          })
        }
        return data
      },
    },
    watch: {
      rangeDate(val) {
        if (val != null && val.split('to').length === 2) {
          this.applyTauxRangeStatisticsAction(val)
        }
      },

      payloadFilter: {
      handler(newValue, oldValue) {
        const keys = Object.keys(newValue);
        keys.forEach((key) => {
          if (newValue[key] == null) {
            this.resetTauxStatistics()
            newValue[key] = "";
            console.log("keys", keys);
          }
        });
      },
      deep: true,
    },

    
     
    },
    mounted(){
    this.resetTauxStatistics() 
    
  },
    created() {
      console.log('TAUX',this.getTauxStatistics)
    },
    methods: {
      ...mapActions('statistics', [
        'getNumbersRangeStatisticsAction',
        'getTauxStatisticsAction',
        'getTauxStatisticsRCAction',
        'getTauxStatisticsCAAction',
        'getTauxStatisticsCCAction',
        'getTauxStatisticsRHAction',
        'getTauxStatisticsARHAction',
        'filterTauxStatisticsAction',
        'filterTauxStatisticsRCAction',
        'filterTauxStatisticsCAAction',
        'filterTauxStatisticsCCAction',
        'filterTauxStatisticsRHAction',
        'filterTauxStatisticsARHAction',
      ]),

      applyFilterTauxStatisticsAction(){
        this.isReLoadFilterData = true
        this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', true)
        const allIsNUl = Object.values(this.payloadFilter).every((el) => el === "");
      if (allIsNUl) {
        this.isReLoadFilterData = false
        this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: "Warning",
              icon: "CheckIcon",
              text: "Veuillez sélectionner au moins un champ du filtre.",
              variant: "warning",
            },
          },
          {
            position: "top-center",
          }
        );
      }
      else{
        if(this.getUser.role.slug === 'super-administrateur'){
          this.filterTauxStatisticsAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(response.data)
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          })
        }

        if(this.getUser.role.slug === 'responsable-relation-client'){
          this.filterTauxStatisticsRCAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(response.data)
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          })
        }

        if(this.getUser.role.slug === 'charge-daffaires'){
          
          console.log("Voir payloadFilter", this.payloadFilter)
          this.filterTauxStatisticsCAAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(response.data)
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          })
        }
        if(this.getUser.role.slug === 'charge-de-clientele'){
          this.filterTauxStatisticsCCAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(response.data)
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          })
        }
        if(this.getUser.role.slug === 'rh'){
          this.filterTauxStatisticsRHAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(response.data)
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          })
        }
        if(this.getUser.role.slug === 'assistant-rh'){
          this.filterTauxStatisticsARHAction(this.payloadFilter)
          .then(response => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(response.data)
          })
          .catch(error => {
            this.isReLoadFilterData = false
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
            console.log(error)
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: "Erreur",
                  icon: "AlertTriangleIcon",
                  text: error.response.data.message,
                  variant: "danger",
                },
              },
              {
                position: "top-center",
              }
            );
          })
        }
      }
      },
  
      resetTauxStatistics() {
        this.isReLoadDataTaux = true,
        this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', true)
        if(this.getUser.role.slug === 'super-administrateur'){
          this.getTauxStatisticsAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }

        if(this.getUser.role.slug === 'responsable-relation-client'){
          this.getTauxStatisticsRCAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'charge-daffaires'){
          this.getTauxStatisticsCAAction()
          .then(() => {
            
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: "",
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'charge-de-clientele'){
          this.getTauxStatisticsCCAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'rh'){
          this.getTauxStatisticsRHAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'assistant-rh'){
          this.getTauxStatisticsARHAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: "",
              type: ""
            }
            this.isReLoadDataTaux = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
       
      },

      eraseTauxStatistics() {
        this.isEraseFilterData = true,
        this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', true)
        if(this.getUser.role.slug === 'super-administrateur'){
          this.getTauxStatisticsAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }

        if(this.getUser.role.slug === 'responsable-relation-client'){
          this.getTauxStatisticsRCAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'charge-daffaires'){
          this.getTauxStatisticsCAAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'charge-de-clientele'){
          this.getTauxStatisticsCCAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'rh'){
          this.getTauxStatisticsRHAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
        if(this.getUser.role.slug === 'assistant-rh'){
          this.getTauxStatisticsARHAction()
          .then(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
          .catch(() => {
            this.type = "",
            this.payloadFilter = {
              quarter: "",
              month: "",
              year: ""
            }
            this.isEraseFilterData = false,
            this.$store.commit('SET_IS_TAUX_STATISTICS_LOADING', false)
          })
        }
       
      },
  
      applyNumbersRangeStatisticsAction(val) {
        const payload = {
          start_date: val.split('to')[0],
          end_date: val.split('to')[1],
        }
        this.isDateRangeStatisticsLoading = true
        this.getNumbersRangeStatisticsAction(payload)
          .then(response => {
            this.isDateRangeStatisticsLoading = false
          })
          .catch(error => {
            this.isDateRangeStatisticsLoading = false
            if (error.response.status !== 422) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: 'Erreur lors du filtrage, Réessayer!',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            } else {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            }
          })
      },
    },
  }
  </script>
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-flatpicker.scss";
  @import "@core/scss/vue/libs/chart-apex.scss";
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  